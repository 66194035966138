const Config = {
	itemLifeSpan: [100, 900],
	jumpTokenUnlockPoints: 10,
	bounceTotalPoints: 200,
	swimVelocities: [1, 2],
	flyVelocity: 6,
	swimVelocity: 6,
	flyTreasureChestQ: 10,
	flyCoinsPerTreasureChest: 10,
	flyBaddyHitCost: 1,
	swimTreasureChestQ: 10,
	swimCoinsPerTreasureChest: 10,
	spaceRowQ: 6,
	spaceColQ: 6,
	spaceDotsPerPlanet: 10,
	magicPillsQ: 10,
	transitionItemsQ: 0,
	jumpBlockSize: [400, 400],
	bounceBlockSize: [1400, 1400],
	flyBlockSize: [200, 200],
	swimBlockSize: [400, 400],
	flyWallHit: -1,
	swimWallHit: -1,
	bounceWallHit: -1.75,
	flyBuffer: 10,
	swimBuffer: 10,
	bounceBuffer: 30,
	boardCompleteButtonAppearDelay: 1000,
	treasureShakeSpeeds: [0.01, 0.025],
	testing: true,
	testingJump: false,
	testingBounce: false,
	baddyHealth: 1,
	flyBaddyQ: 10,
	swimBaddyQ: 10,
	mobileOnlyScaling: 0.75,
	mobileOnlyScalingJump: 0.5,
	mobileOnlyScalingFly: 0.15,
	mobileOnlyScalingSwim: 0.75,
	mobileOnlyDimensionsLandscape: [550, 230],
	mobileOnlyDimensionsPortrait: [300, 450],
	colors: [0xFF00FF, 0xFF0000, 0xFFFF00, 0xFF9900, 0x33FF00],
	rainbowColors: [0x9400D3, 0x4B0082, 0x0000FF, 0x00FF00, 0xFFFF00, 0xFF7F00, 0xFF0000]
  }
export default Config;