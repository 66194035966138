import './BamLogo.scss';
function BamLogo() {
	return ( 
	<svg id="bam-logo" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 99.13 129.87">
	<g id="Layer_19" data-name="Layer 19">
	  <path className="bam-logo-cls-2" d="m96.35,38.02c2.79-9.12,2.23-18.79,2.23-18.79,0,0-2.04-14.89-5.95-17.3-1.58-.98-4.14-1.52-5.21-1.41-1.58.16-3.4,1.32-5.02,2.71-2.99,2.57-16.19,24.56-17.86,27.16-1.67,2.6-2.05,9.58-2.05,9.58-2.17-.34-4.34-.59-6.5-.77-1.86-.15-3.72-.22-5.58-.24-.28,0-.57-.02-.85-.02-.26,0-.52.01-.78.02-1.89.02-3.77.09-5.65.24-2.16.17-4.33.43-6.5.77,0,0-.37-6.98-2.05-9.58-1.67-2.6-14.87-24.6-17.86-27.16-1.63-1.4-3.44-2.56-5.02-2.71-1.07-.11-3.63.44-5.21,1.41C2.59,4.34.55,19.23.55,19.23c0,0-.56,9.67,2.23,18.79-.41-1.43.56,3.35.56,3.35,0,0-1.12,3.72,2.05,10.98s5.4,6.7,5.4,6.7c0,0,1.86,8.56,2.42,9.86.56,1.3.93,3.72.93,3.72,0,0-1.86,8.93.74,15.26,2.6,6.33,8.93,16,9.49,17.49.56,1.49,4.09,11.53,5.21,13.4,1.12,1.86,8.55,8.74,20.65,10.6,12.09-1.86,18.23-8.74,19.35-10.6s4.65-11.91,5.21-13.4c.56-1.49,6.88-11.16,9.49-17.49,2.6-6.33.74-15.26.74-15.26,0,0,.37-2.42.93-3.72.56-1.3,2.42-9.86,2.42-9.86,0,0,2.23.56,5.4-6.7s2.05-10.98,2.05-10.98c0,0,.97-4.78.56-3.35Z"/>
	</g>
	<g id="Layer_20" data-name="Layer 20">
	  <path className="bam-logo-cls-1" d="m64.45,85.83s-.73-12,10.23-8.84c0,0,3.52,6.42-5.12,8.84h-5.11Z"/>
	  <path className="bam-logo-cls-1" d="m37.66,85.83s.73-12-10.23-8.84c0,0-3.52,6.42,5.12,8.84h5.11Z"/>
	</g>
  </svg> );
}

export default BamLogo;